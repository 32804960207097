import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import { dateFormatter  } from "./date"
import LocalStorage from "./localstorage"
import axiosInstance from "./axios"

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export function getInitials(name) {
  return name
    ?.split(" ")
    .slice(0, 2)
    .map((n) => n[0])
    .join("")
    .toUpperCase()
}

export function getChatMetaDataObject(chat, loggedInUser) { 
  if (chat && chat.type === 'direct') {
    const participant = chat?.participants?.find(p => p._id !== loggedInUser._id) 
    return {
      name: participant.name,
      image: participant.image,
      lastMessage: chat.lastMessage
    }
  }
  else {
    return chat
  }
}

export function groupMessagesByDate(messages) {
  return messages.reduce(function (rv, message) {
    const date = new Date(message.createdAt).toLocaleDateString();
    (rv[date] = rv[date] || []).push(message);
    return rv;
  }, {})
}

export {
  axiosInstance,
  LocalStorage,
  dateFormatter
}
