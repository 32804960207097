import '@fontsource-variable/jetbrains-mono';
import "./styles/globals.css";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { useAuth } from './context/auth';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from './context/auth';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { SocketContextProvider } from './context/socket';
import { ChatContextProvider } from './context/chats';
import App from './App';

const ChatProvider = ({ children }) => {
  const { user, token } = useAuth()
  const isAuthenticated = !!(user?._id && token) 
  
  return <>
    {
      isAuthenticated ?
        <ChatContextProvider>
          {children}
        </ChatContextProvider>
        :
        <> {children} </>
    }
  </>
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""} >
        <AuthContextProvider>
          <SocketContextProvider>
            <ChatProvider>
              <App />
            </ChatProvider>
          </SocketContextProvider>
        </AuthContextProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
