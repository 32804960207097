import { useState, useEffect, useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../context/auth';
import { LocalStorage, axiosInstance } from '../utils'; 
import AuthForm from '../components/AuthForm';

export default function Auth() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const redirect = searchParams.get("redirect")
  const { setUser, setToken } = useAuth()

  const [errorMessage, setErrorMessage] = useState("")
  const [type, setType] = useState("login")

  const onSuccess = useCallback(function ({ token, user }) {
    setUser(user)
    setToken(token)
    LocalStorage.set("user", user)
    LocalStorage.set("token", token)
    let pattern = /^\/chat\/[0-9a-fA-F]{24}\/invite$/;

    if (redirect && pattern.test(redirect)) navigate(redirect)
    else navigate('/chat')
  }, [setUser, setToken, navigate, redirect])

  const handleGoogleAuth = useCallback(
    async function (response) {
      try {
        const { data } = await axiosInstance.post('/auth/google', response)
        onSuccess(data)
      }
      catch (error) {
        console.log("GoogleLogin error: ", error)
        setErrorMessage(error.response?.data?.errorMessage || "Something went wrong")
      }
    }, [onSuccess]
  )



  async function submitHandler(e) {
    e.preventDefault()
    const form = e.target

    try {
      const res = await axiosInstance.post(`/auth/${type}`, {
        name: form.name.value,
        email: form.email.value,
        password: form.password.value
      })
      onSuccess(res.data)
    } catch (error) {
      console.log(error);
      setErrorMessage(error.response?.data?.errorMessage || "Something went wrong")
    }
  }

  // handle message from react-native-webview
  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.addEventListener('message', (event) => {
        let data = JSON.parse(event.data)
        handleGoogleAuth(data)
      }
      )
    }
    // return () => window.removeEventListener('message')
  }, [handleGoogleAuth])

  return (
    <AuthForm
      type={type}
      setType={setType}
      submitHandler={submitHandler}
      errorMessage={errorMessage}
      handleGoogleAuth={handleGoogleAuth} />
  )
}
