import { useState, useEffect } from 'react'
import {useNavigate } from 'react-router-dom' 
import axiosInstance from "../utils/axios" 
import {  getInitials } from '../utils'
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar'
import { Input } from './ui/input' 
import { ScrollArea } from './ui/scroll-area'
import { Button } from './ui/button'
import { MdClose, MdGroupAdd, MdOutlineDone } from "react-icons/md"
import { AiOutlineLoading3Quarters } from "react-icons/ai"
import { Separator } from './ui/separator'
import Loading from './Loading'
import { Textarea } from './ui/textarea'


export default function NewChat({ onClose }) {
  const navigate = useNavigate()
  // const { user } = useAuth()

  // if (!user) {
  //   navigate("/auth")
  // }

  const [users, setUsers] = useState([])
  const [isCreatingGroupChat, setIsCreatingGroupChat] = useState(false)
  const [chatName, setChatName] = useState("")
  const [chatDescription, setChatDescription] = useState("")
  const [selectedUsers, setSelectedUsers] = useState([])
  const [creatingChat, setCreatingChat] = useState(false)

  useEffect(() => {
    axiosInstance.get("/chat/users").then((res) => {
      setUsers(res.data.data)
    }
    ).catch((err) => {
      console.log("getUsers() failed: ", err)
    })

  }, [])

  async function createGroupChat() {
    setCreatingChat(true)
    console.log(chatName)
    try {
      const res = await axiosInstance.post('/chat', {
        name: chatName,
        description: chatDescription,
        participants: selectedUsers.map(u => u._id)
      })
     navigate(`/chat/${res.data.data._id}`)
    } catch (error) {
      console.log(error)
    }
  }

  async function createDirectChat(userId) {
    setCreatingChat(true)
    try {
      const res = await axiosInstance.post(`/chat/direct/${userId}`) 
     navigate(`/chat/${res.data.data._id}`) 
    } catch (error) {
      console.log(error)
    }
  }

  return (

    <>
      <ScrollArea className="scroll-smooth relative pb-10" >

        <h3 className='font-bold text-xl text-primary mb-4 '>New chat</h3>


        {isCreatingGroupChat ?
          <>
            <Input
              className="w-[calc(100%-8px)] mx-1 my-2"
              placeholder="Chat name"
              value={chatName}
              onChange={(e) => setChatName(e.target.value)}
            />
            <Textarea
              className="w-[calc(100%-8px)] mx-1 my-2"
              placeholder="Chat description"
              value={chatDescription}
              onChange={(e) => setChatDescription(e.target.value)}
            />

            <div className="w-[calc(100%-8px)] mx-1 text-muted-foreground text-[10px] px-3 mt-3 rounded-sm ">
              Select participants
            </div>

            <div className="w-[calc(100%-8px)] mx-1  flex flex-wrap">
              {selectedUsers.map((user, idx) => {
                return (
                  <div key={idx} className="flex text-xs items-center my-1 mx-1 bg-secondary text-secondary-foreground rounded  ">
                    <div className=' px-2 py-1'>
                      {user.name}
                    </div>
                    <Separator orientation="vertical" />
                    <Button variant='outline'
                      className='p-1 bg-muted text-muted-foreground hover:bg-muted-foreground hover:text-muted h-6'
                      onClick={() => setSelectedUsers(users => users.filter(u => u._id !== user._id))}>
                      <MdClose className='h-4 w-4' />
                    </Button>
                  </div>
                )
              }
              )}
            </div>

            {creatingChat && <Loading />}
            {users.map((user, idx) => {
              return (
                <button key={idx}
                  disabled={selectedUsers.find(u => u._id === user._id)}
                  onClick={() => setSelectedUsers(users => [...users, user])}
                  className="w-full flex items-center gap-2 py-1.5 my-1 px-3 hover:bg-secondary rounded disabled:text-muted-foreground disabled:bg-muted">
                  <Avatar>
                    <AvatarImage src={user.image || ""} />
                    <AvatarFallback>{getInitials(user.name)}</AvatarFallback>
                  </Avatar>
                  <div className="flex gap-2 py-1.5 my-1 px-3 hover:bg-secondary rounded w-full ">
                    <div className='flex items-center justify-between w-full'>
                      <h3 className='text-sm font-bold'>{user.name}</h3>
                      <span className='text-sm font-bold text-muted-foreground'>
                        {selectedUsers.find(u => u._id === user._id) && <MdOutlineDone className="h-6 w-6" />}
                      </span>
                    </div>
                  </div>
                </button>
              )
            }
            )}

            <div className="flex gap-4 absolute bottom-0 w-full">
              <Button onClick={onClose} variant="outline" className="w-full">
                Cancel
              </Button>
              <Button onClick={createGroupChat}
                disabled={chatName.length === 0 || selectedUsers.length === 0}
                className="w-full">
                {creatingChat ? <AiOutlineLoading3Quarters className="animate-spin" /> : "Create"}
              </Button>
            </div>

          </>
          :
          <>
            <button onClick={() => setIsCreatingGroupChat(true)}
              className="w-full flex gap-2 py-1.5 my-1 px-3 hover:bg-secondary rounded">
              <Avatar className="p-1.5 bg-secondary ">
                <MdGroupAdd className=' ml-0.5 h-full w-full' />
              </Avatar>
              <div className="flex gap-2 py-1.5 my-1 px-3 hover:bg-secondary rounded">
                <div className='flex items-center justify-between w-full'>
                  <h3 className='text-sm font-bold'>New Group</h3>
                </div>
              </div>
            </button>

            <div className="bg-muted text-muted-foreground text-[10px] px-3 rounded-sm">
              Available users
            </div>
            {creatingChat && <Loading />}
            {users.map((user, idx) => {
              return (
                <button key={idx}
                  onClick={() => createDirectChat(user._id)}
                  className="w-full flex gap-2 py-1.5 my-1 px-3 hover:bg-secondary rounded">
                  <Avatar>
                    <AvatarImage src={user.image || ""} />
                    <AvatarFallback>{getInitials(user.name)}</AvatarFallback>
                  </Avatar>
                  <div className="flex gap-2 py-1.5 my-1 px-3 hover:bg-secondary rounded">
                    <div className='flex items-center justify-between w-full'>
                      <h3 className='text-sm font-bold'>{user.name}</h3>
                      <span className='text-sm font-bold text-muted-foreground'>

                      </span>
                    </div>
                  </div>
                </button>
              )
            }
            )}
            <Button onClick={onClose} variant="outline" className="w-full absolute bottom-0 left-0 ">
              Cancel
            </Button>
          </>
        }

      </ScrollArea>
    </>




  )
}

