import { useContext, createContext, useState, useEffect } from "react"; 
import { useNavigate } from 'react-router-dom'
import { LocalStorage } from '../utils'
import Loading from '../components/Loading'

export const AuthContext = createContext({ user: null, setUser: null, loading: false });

export const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate()
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null); 
  const [loading, setLoading] = useState(true);

  function logout() {
    // setUser(null)
    LocalStorage.remove("user")
    LocalStorage.remove("token")
    setToken(null)
    setUser(null)
    navigate("/auth")
  }

  useEffect(() => {
    setLoading(true)
    const user = LocalStorage.get("user")
    const token = LocalStorage.get("token")

    if (token) {
      setToken(token)
    }

    if (user) {
      setUser(user)
    }

    setLoading(false)
  }, []);

  useEffect(() => {
    if (user) {
      LocalStorage.set("user", user)
    }

    if (token) {
      LocalStorage.set("token", token)
    }

  }, [user, token])


  return (
    <AuthContext.Provider value={{ user, token, setToken, setUser, logout }} >
      {loading ?
        <Loading />
        : children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext)
