import { Button } from "./ui/button"
import { GoogleLogin } from "@react-oauth/google"
import { FcGoogle } from "react-icons/fc";

export default function AuthForm({ type, setType, submitHandler, handleGoogleAuth, errorMessage }) {

  return (
    <div className="h-screen flex items-center justify-center ">
      {/* card  */}
      <div className='rounded shadow p-6 bg-secondary w-full max-w-sm mx-3'>

        <h2 className="text-2xl capitalize mb-4 font-medium text-center">
          {type}
        </h2>

        <form onSubmit={submitHandler} method='POST'>

          {(type === "register") &&
            <div className="mb-4">
              <label htmlFor="name" className="block mb-1.5 text-sm font-medium ">Your name</label>
              <input type="name" name="name"
                className="bg-card border border-secondary text-secondary-foreground text-sm rounded focus:ring-primary focus:border-primary block w-full p-2.5 "
                placeholder="Name" required />
            </div>
          }

          <div className="mb-4">
            <label htmlFor="email" className="block mb-1.5 text-sm font-medium">Your email</label>
            <input type="email" name="email"
              className="bg-card border border-secondary text-secondary-foreground text-sm rounded focus:ring-primary focus:border-primary block w-full p-2.5 "
              placeholder="name@company.com" required />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block mb-1.5 text-sm font-medium">Your
              password</label>
            <input type="password" name="password" placeholder='Password'
              className="bg-card border border-secondary text-secondary-foreground text-sm rounded focus:ring-primary focus:border-primary block w-full p-2.5 "
              required />
          </div>
          {(errorMessage && (errorMessage !== ""))
            &&
            <p className='text-red-500 text-sm font-medium flex gap-2 '>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round"
                  d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
              </svg>
              <span>
                {errorMessage}
              </span>
            </p>
          }

          {/* <!-- Submit btn  --> */}
          <button type="submit"
            className="mt-2 capitalize text-primary-foreground bg-primary hover focus:ring focus:outline-none focus:ring-primary font-medium rounded w-full px-5 py-2 shadow text-center ">
            {type}
          </button>
        </form>

        <div className="text-gray-400 text-xs mx-auto my-4 w-fit">
          OR
        </div>

        {/* <!-- Google auth btn --> */}
        <div className="text-center [&>div]:w-fit [&>div]:mx-auto">
          {
            !!(window?.ReactNativeWebView)
              ?
              <Button className="text-primary-foreground bg-primary text-sm flex items-center gap-4 justify-center mx-auto font-medium hover:underline"
                onClick={() => window.ReactNativeWebView?.postMessage(JSON.stringify({ action: "googleAuth" }))}>
                <FcGoogle className="text-2xl" />
                <span>
                  Continue with Google
                </span>
              </Button>
              :
              <GoogleLogin onSuccess={handleGoogleAuth} useOneTap auto_select />
          }
        </div>

        {/* <!-- suggestions --> */}
        {(type === "register") &&
          <button className='text-primary text-sm text-center w-full block mt-6 font-medium hover:underline'
            onClick={() => setType('login')}>
            Already a user? Sign in here
          </button>}

        {(type === "login") &&
          <button className='text-primary text-sm text-center w-full block mt-6 font-medium hover:underline'
            onClick={() => setType('register')}>
            New here? Sign up now
          </button>}

      </div>
    </div>

  )
} 
