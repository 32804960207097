import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { dateFormatter } from '../utils';
import { getChatMetaDataObject, getInitials } from '../utils';
import { useAuth } from '../context/auth';
import { useChat } from '../context/chats';
import { Avatar, AvatarFallback, AvatarImage } from '../components/ui/avatar';
import { Dialog, DialogContent, DialogTrigger } from '../components/ui/dialog';
import { ScrollArea } from '../components/ui/scroll-area';
import { Button } from '../components/ui/button';
import Header from '../components/Header';
import NewChat from '../components/NewChat';
import Loading from '../components/Loading';

function ChatItem({ chat, user, unreadMessagesCount }) {
  const lastMessage = getChatMetaDataObject(chat, user)?.lastMessage;

  return (
    <div>
      <Link to={`/chat/${chat._id}`} className=''>
        <div className='flex items-center gap-3 py-1.5 my-1 px-3 hover:bg-secondary rounded'>
          {/* avatar */}
          <Avatar>
            <AvatarImage src={getChatMetaDataObject(chat, user).image || ''} />
            <AvatarFallback>
              {getInitials(getChatMetaDataObject(chat, user).name)}
            </AvatarFallback>
          </Avatar>
          {/* info container  */}
          <div className='w-full '>
            {/* name & last message */}
            <div className='flex justify-between '>
              {/* name  */}
              <h3 className='text-sm mb-0.5 font-bold'>
                {getChatMetaDataObject(chat, user).name}
              </h3>
              {/* updatedAt */}
              <span className='text-[10px] text-gray-400'>
                {dateFormatter(lastMessage?.updatedAt)}
              </span>
            </div>
            {/* lastMessage text and unread count  */}
            <div className='flex justify-between'>
              {/* last message text */}
              <p className='text-secondary-foreground text-[10px] line-clamp-1 '>
                {lastMessage?.text}
              </p>
              {/* unreadMessagesCount */}
              {unreadMessagesCount > 0
                && (
                  <span className='mb-1.5 mr-1 text-[10px] shrink-0 text-center h-[14px] min-w-[14px] rounded-full px-0.5 bg-primary text-primary-foreground'>
                    {unreadMessagesCount}
                  </span>
                )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default function Chat() {
  const { user } = useAuth();
  const { chats, unreadMessages, loading } = useChat();
  const containerRef = useRef(null);  
  const innerContainerRef = useRef(null);
  const [rightOffset, setRightOffset] = useState(0)

  const [showNewChatDialog, setShowNewChatDialog] = useState(false);

  useEffect(() => {
    setRightOffset((containerRef.current?.offsetWidth / 2) - (innerContainerRef.current?.offsetWidth / 2) + 6)

    function handleResize() { 
      setRightOffset((containerRef.current?.offsetWidth / 2) - (innerContainerRef.current?.offsetWidth / 2) + 6)
    } 
    window.addEventListener('resize', handleResize); 
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='h-full max-h-screen '>
      <Header />
      {loading &&
        <Loading />}
      <ScrollArea ref={containerRef} className='h-[calc(100%-(56px))] scroll-smooth relative'>
        {/* chats list */}
        <div ref={innerContainerRef} className='list-none min-h-full container'>
          {chats.map((chat, idx) => (
            <ChatItem
              chat={chat}
              user={user}
              unreadMessagesCount={
                unreadMessages?.filter((m) => m.chat === chat._id).length
              }
              key={idx}
            />
          ))}
          {/* <!-- floating action button --> */}
          <Dialog open={showNewChatDialog} onOpenChange={setShowNewChatDialog}>
            <DialogTrigger asChild>
              <Button
                size='icon'
                style={{ bottom: '2rem', right: rightOffset}}
                className='absolute h-12 w-12 rounded-full bg-primary hover:bg-primary/80 text-3xl text-primary-foreground'
              >
                <div>+</div>
              </Button>
            </DialogTrigger>
            <DialogContent
              showCloseButton={false}
              className='h-full sm:h-[calc(100%-(56px))]'
            >
              <NewChat onClose={() => setShowNewChatDialog(false)} />
            </DialogContent>
          </Dialog>
        </div>
      </ScrollArea>
    </div>
  );
}
