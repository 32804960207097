import socketio from 'socket.io-client';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { LocalStorage } from '../utils';
// Function to establish a socket connection with authorization token
const getSocket = () => {
  const token = LocalStorage.get("token"); // Retrieve jwt token from local storage or cookie

  // Create a socket connection with the provided URI and authentication
  return socketio(process.env.REACT_APP_SERVER_URL, {
    withCredentials: true,
    auth: { token },
  });
};
const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketContextProvider = ({ children }) => {
  const [socket, setSocket] = useState();

  useEffect(() => { 
    setSocket(getSocket());
  }, []);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
}
