import ChatMessage from "./ChatMessage";
import { ScrollArea } from "./ui/scroll-area"
import { useRef, useEffect } from "react"

export default function MessagesList({ groupedMessages }) {
  const scrollViewportRef = useRef()

  // scroll to bottom on new message
  useEffect(() => {
    scrollViewportRef.current.scrollTo(0, scrollViewportRef.current.scrollHeight)
  }, [groupedMessages])

  return (

    <ScrollArea
      className=" h-[calc(100%-(56px+48px))] relative scroll-smooth"
      viewportRef={scrollViewportRef}>
      <div className='list-none h-full container'>
        {Object.keys(groupedMessages).map((date, idx) => {
          let lastSender;
          return (
            <div key={idx}>
              <div className='text-[10px] font-extralight bg-muted text-muted-foreground rounded px-2 py-1 my-2 w-fit mx-auto '>
                {date}
              </div>
              {
                groupedMessages[date].map((message, idx) => {
                  const showSender = lastSender !== message.sender?._id;
                  lastSender = message.sender?._id
                  return (<ChatMessage key={idx} message={message} showSender={showSender} />)
                }
                )
              }
            </div>)
        }
        )}
      </div>
    </ScrollArea>
  )
}
