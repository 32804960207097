import axios from "axios";
import { LocalStorage } from "../utils";

const baseURL = process.env.REACT_APP_SERVER_URL;

const axiosInstance = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json"
  },
});

axiosInstance.interceptors.request.use(
  function (config) {
    // Retrieve user token from local storage
    const token = LocalStorage.get("token");
    // Set authorization header with bearer token
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 403) {
      // redirect to login page
      LocalStorage.remove("token")
      LocalStorage.remove("user")
      window.location = '/auth';
    } else {
      console.log("LOG from axios interceptor: ", error)
      return Promise.reject(error);
    }
  }
);


export default axiosInstance;
