import { useEffect, useRef, useState } from 'react' 
import { useParams } from 'react-router-dom'
import { VscSend } from "react-icons/vsc"
import { groupMessagesByDate, axiosInstance } from '../utils'  
import { useChat } from '../context/chats'
import { useSocket } from '../context/socket'
import Header from '../components/Header'
import MessagesList from "../components/MessagesList"

export default function Chat() {
  const { chatId } = useParams() 

  const { socket } = useSocket()
  const { messages, activeChat, setActiveChat } = useChat()
  const inputRef = useRef()
  const [messageString, setMessageString] = useState('')

  useEffect(() => {
    socket?.emit("joinChat", chatId)
    setActiveChat(chatId)
    return () => {
      setActiveChat({})
    }
  }, [socket, chatId, setActiveChat])

  function sendChatMessage(e) {
    e.preventDefault()
    const message = { text: messageString }
    setMessageString('')
    inputRef.current && inputRef.current.focus()
    axiosInstance.post(`/message/${chatId}`, message)
      .then(res => {
        console.log(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  }, []);


  return (
    <div key={chatId} className='h-full'>
      <Header variant='chat' chat={activeChat} />
      {/* height of messages-list = fullHeight - (HeaderHeight + MessageBoxHeight) */}
      <>
        {/* messages list */}
        <MessagesList groupedMessages={groupMessagesByDate(messages)} />

        {/* <!-- message form --> */}
        <div className="container fixed left-0 right-0 bottom-0">
          <form
            className='flex h-12 py-1 relative'
            onSubmit={sendChatMessage}>
            <input
              ref={inputRef}
              value={messageString}
              onChange={e => setMessageString(e.target.value)}
              className='bg-primary-foreground shadow m-1 ring-1 ring-primary flex-1 peer rounded border-none px-2 text-sm focus:ring-primary focus:outline-none'
              autoComplete="off" />
            <button title='send message'
              className='absolute right-0 m-1 border-none bg-primary peer-focus:bg-primary focus:bg-primary active:bg-primary text-primary-foreground outline-none h-8 w-9 rounded-tr rounded-br flex justify-center items-center text-2xl font-bold '
              type="submit">
              <VscSend />
            </button>
          </form>
        </div>
      </>
    </div>
  )
} 
