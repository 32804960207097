import "../styles/Loader.css";

// function Loading() {
//   return (
//     <div className="bg-black text-white flex items-center justify-center absolute inset-0 z-50">Loading...</div>
//   )
// }

function Loader( ) {
  return (
    <div className='absolute overflow-hidden inset-0 bg-[#111] z-50'>
      <div className="body">
        <span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </span>
        <div className="base">
          <span></span>
          <div className="face"></div>
        </div>
      </div>
      <div className="longfazers">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <p className='text'>
        almost there...
      </p>
    </div>
  )
}

export default Loader;
