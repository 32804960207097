export default function ChatMessage({ message, showSender }) { 
  return (
    <li className='mb-1 w-full max-w-max rounded bg-secondary px-2 py-1 shadow'>
      {showSender &&
        <span className="text-xs font-medium text-blue-400 mr-4 block">
          {message.sender?.name}</span>
      }
      <div className="">
        <span className="inline-block align-top text-sm text-secondary-foreground font-normal">
          {message.text}
        </span>
        <span className="inline-block text-right text-[10px] font-normal text-gray-400 ml-2 pt-1">
          {new Date(message.createdAt).toLocaleTimeString()}
        </span>
      </div>
    </li>
  )
}
