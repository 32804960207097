// write a simple react app that uses the pages/auth page

import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import Auth from "./pages/auth";
import Chats from "./pages/Chats";
import Chat from "./pages/Chat";
import ChatInvite from "./pages/ChatInvite"
import { useAuth } from "./context/auth";


const PrivateRoute = ({ isAuthenticated }) => {
  return isAuthenticated ? <Outlet /> : <Navigate to="/auth" />;
};

export default function App() {
  const { user, token } = useAuth()
  const isAuthenticated = !!(user?._id && token)

  return (
    <Routes>

      {/*  root route  */}
      <Route path="/" element={<Navigate to={isAuthenticated ? "/chat" : "/auth"} />} />

      {/* Authenticate user */}
      <Route exact path="/auth"
        element={isAuthenticated ? <Navigate to="/chat" /> : <Auth />} />

      {/* Chats */} 
      <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
        <Route path="/chat" element={<Chats />} />
        <Route path="/chat/:chatId" element={<Chat />} />
        <Route path="/chat/:chatId/invite" element={<ChatInvite />} />
      </Route>

    </Routes>

  )
}
