import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { axiosInstance, getInitials } from '../utils'
import { useAuth } from '../context/auth'
import { Button } from '../components/ui/button'
import { Skeleton } from '../components/ui/skeleton'
import { Avatar, AvatarFallback, AvatarImage } from '../components/ui/avatar'

export default function Page() {
  const { chatId } = useParams()
  const { user } = useAuth()

  const navigate = useNavigate()
  const [chat, setChat] = useState({})
  const [alreadyInChat, setAlreadyInChat] = useState(false)
  const [loading, setLoading] = useState(true)
  const [joining, setJoining] = useState(false)

  useEffect(() => {
    axiosInstance.get(`/chat/${chatId}`)
      .then((res) => {
        let chat = res.data.data
        if (!chat) return
        if (chat?.participants?.some(({ _id }) => _id === user._id))
          setAlreadyInChat(true)
        setChat(chat)
        setLoading(false)
      }).catch((err) => {
        console.log("getChat() failed: ", err)
      })
  }, [chatId, navigate, setChat, user._id])

  function joinChat(chatId) {
    setJoining(true)
    axiosInstance.post(`/chat/${chatId}/join`)
      .then((res) => {
        console.log(res.data)
        navigate(`/chat/${chatId}`)
      }).catch((err) => {
        console.log("joinChat() failed: ", err, err.response?.data?.message)
        setJoining(false)
      })
  }

  return (
    <div className='w-full h-full flex items-center justify-center'>
      {loading
        ?
        <div className="w-full max-w-xs bg-card border border-input rounded shadow ">
          <div className="flex flex-col items-center py-10">
            <Skeleton className='w-24 h-24 mb-3 rounded-full ' />
            <Skeleton className='w-2/4 h-7 mb-3' />
            <Skeleton className='w-4/5 h-3 mb-2' />
            <Skeleton className='w-3/5 h-3 mb-3' />
            <div className="flex mt-4 md:mt-6">
              <Button
                disabled={loading} >
                ...
              </Button>
            </div>
          </div>
        </div>
        :
        <div className="w-full max-w-xs bg-card border border-input rounded shadow ">
          <div className="flex flex-col items-center py-10">
            <Avatar className="w-24 h-24 mb-3 rounded-full shadow-lg border border-gray-700">
              <AvatarImage
                src={chat?.image}
                alt={chat?.name} />
              <AvatarFallback className="text-3xl">
                {getInitials(chat?.name || "")}
              </AvatarFallback>
            </Avatar>
            <h5 className="mb-1 text-xl font-medium text-primary">{chat?.name}</h5>
            <span className="text-sm text-muted-foreground dark:text-gray-400">{chat?.description || "No description"}</span>
            <div className="flex mt-6 space-x-3 md:mt-6">
              {alreadyInChat ?
                <Button variant="outline" onClick={() => navigate(`/chat/${chatId}`)} >
                  View Chat
                </Button>
                :
                <Button
                  disabled={loading}
                  onClick={() => joinChat(chatId)}>
                  {joining ? "Joining..." : "Join"}
                </Button>}
            </div>
          </div>
        </div>}
    </div>
  )
}
